<template>
  <div class="searchRooms">
    <div class="searchRooms__form">
      <div class="iconReview">
        <i class="fas fa-search iconReview__i" @click="showlistRooms"></i>
        <input class="iconReview__input" type="search" name="search" placeholder="Buscar" v-model="searchValue" @keyup="autoSearch" @click="showlistRooms" autocomplete="off" />
      </div>
      <div class="searchRooms__results" v-if="searchRooms && searchRooms.length">
        <div class="searchRooms__item" @click="filterData(room.roomName)" v-for="(room, midx) in searchRooms" :key="midx">
          <div>{{ room.roomName }}</div>
          <div class="searchRooms__role">{{ room.officeName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import axios from "@/api/axios";
  export default {
    data() {
      return {
        rooms: [],
        searchValue: "",
        searchRooms: [],
        searchValue: "",
      };
    },
    computed: {
      ...mapGetters("users", { getUsers: "getData" }),
    },
    methods: {
      //reinicia la data para ocultar la lista
      initListUser() {
        this.rooms = [];
        this.searchRooms = [];
      },
      // //funcion reinicia el input del buscador
      resetSearch() {
        this.searchValue = "";
      },
      //inicializa la data para mostrar al iniciar
      async showlistRooms() {
        let res = await axios.get(`/rooms?all=true`);
        this.rooms = [...res.data.rooms];
        this.searchRooms = this.rooms;
      },
      //emite evento con el nombre de usuario como query a NotifyList
      async filterData(user) {
        this.$emit("sendRoomQueryMaintenance", user);
        this.searchValue = user;
        this.initListUser();
      },
      //inicializa la data deacuerdo al filtro obtenido
      async autoSearch() {
        const word = event.srcElement.value.trim();
        if (!word) {
          this.searchRooms = this.rooms;
        } else {
          this.searchRooms = this.rooms.filter((room) => {
            return room?.roomName?.toLowerCase().startsWith(word.toLowerCase()) || room?.officeName?.toLowerCase().startsWith(word.toLowerCase());
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .searchRooms {
    &__item {
      padding: 10px;
      font-size: 16px;
      text-align: left;
      text-transform: capitalize;
      color: $mine_shaft;
      border-bottom: 1px solid $porcelain;
      &:hover {
        background: $porcelain;
      }
    }
    &__role {
      font-size: 12px;
      color: $lipstick;
    }
    &__results {
      width: auto;
      min-width: 200px;
      max-height: 270px;
      position: absolute;
      z-index: 52;
      margin-top: 20px;
      margin-left: -10px;
      overflow-x: auto;
      border-radius: 5px;
      background: $white;
    }
    .iconReview {
      position: relative;
      &__i {
        top: 5px;
        left: 9px;
        position: absolute;
        color: $lipstick;
      }
      &__input {
        max-width: 160px;
        padding-left: $mpadding * 2;
        border-radius: 5px;
        border: 1px solid $lipstick;
        outline: none;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .searchRooms {
      .iconInput {
        position: relative;
        &__i {
          left: 3px;
        }
        &__input {
          padding-left: $mpadding * 1.5;
        }
      }
    }
  }
</style>
