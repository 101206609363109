<template>
  <div class="filterMaintenance" v-bind="showResetFilter()">
    <div class="wrapShow" @click="maskNotifications"></div>
    <div class="filterMaintenance__nav">
      <div class="filterMaintenance__selects" :key="resetSelect">
        <searchMaintenance class="filterMaintenance__Search" ref="searchmantenaince" @sendRoomQueryMaintenance="searchRoom" @click.native="toggleSearch" />
        <div class="filterMaintenance__item">
          <select class="filterMaintenance__select" name="statusMaintenence" @change="setFilter">
            <option value="allMaintenance">Mantenimiento</option>
            <option value="true">Requerido</option>
            <option value="false">No requerido</option>
            <option value="repair">Reparadas</option>
          </select>
        </div>
        <div class="filterMaintenance__item">
          <select class="filterMaintenance__select" name="isEnable" @change="setFilter">
            <option value="allState">Estado habitación</option>
            <option value="true">Habilitadas</option>
            <option value="false">Deshabilitadas</option>
          </select>
        </div>
        <div class="filterMaintenance__item">
          <select class="filterMaintenance__select" name="officeName" @change="setFilter">
            <option value="allsedes">Sedes</option>
            <option v-for="(office, idx) in getOffices" :value="office.officeName" :key="idx">{{ office.officeName }}</option>
          </select>
        </div>
        <div v-if="showReset">
          <button class="filterMaintenance__btn" @click="setFilter('reset')">
            <i class="filterMaintenance__icons fas fa-times"></i>
            <span>Quitar Filtros</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import setfilterMixin from "@/mixins/setfilter";
  import searchMaintenance from "@/views/main/reviewsAdmin/searchMaintenance.vue";
  export default {
    props: {},
    mixins: [setfilterMixin],
    data() {
      return {
        cities: this.$global.dictionary.cities,
        path: "/maintenence",
        showReset: false,
        resetSelect: 1,
      };
    },
    components: { searchMaintenance },
    methods: {
      maskNotifications() {
        this.$refs.searchmantenaince.initListUser();
        this.toggleSearch();
      },
      toggleSearch() {
        const wrap = document.querySelector(".wrapShow");
        wrap.classList.toggle("wrapShow-active");
        if (!wrap.classList.contains("wrapShow-active")) {
          this.$refs.searchmantenaince.initListUser();
        }
      },
      searchRoom(model) {
        this.setFilter({ srcElement: { name: "room", value: model } });
      },
      showResetFilter() {
        let currentQuery = this.$route.fullPath.substring(1);
        currentQuery !== "maintenence" ? (this.showReset = true) : (this.showReset = false);
      },
    },
    beforeMount() {
      this.showResetFilter();
    },
    computed: {
      ...mapGetters("offices", { getOffices: "getData" }),
    },
  };
</script>

<style lang="scss">
  .filterMaintenance {
    .wrapShow {
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      z-index: 50;
      background: rgba(0, 0, 0, 0.1);
      &-active {
        display: block;
      }
    }
    &__nav {
      display: flex;
      min-height: 40px;
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 32px;
      border-radius: 10px;
      font-family: 16px;
      font-size: $label-forms;
      background: $white;
      box-shadow: 0 2px 5px $alto;
      align-items: center;
    }
    &__Search {
      margin-left: 15px;
    }
    &__selects {
      @include Row(flex-start);
      width: 100%;
      margin: auto;
      overflow-x: auto;
      justify-content: space-around;
    }
    &__item {
      margin: 0 10px;
    }
    &__select {
      width: auto;
      border: none;
      color: $lipstick;
      font-weight: bold;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__btn {
      border: none;
      color: $lipstick;
      font-weight: bold;
      background: transparent;
      white-space: nowrap;
      margin: 0 10px;
      &:focus {
        outline: none;
      }
    }
    &__icons {
      margin: 0 3px 0 10px;
    }
  }
</style>
