<template>
  <div class="rooms">
    <filters />
    <div class="rooms__head">
      <div :class="['rooms__item', classHead(item)]" v-for="(item, idx) in head" :key="idx">
        {{ item }}
      </div>
    </div>
    <div class="rooms__container" v-bind="getfilterRooms">
      <div v-for="(room, idx) in rooms" :key="idx">
        <div class="rooms__card">
          <div class="rooms__info">
            <div class="rooms__li">
              <span class="mobile">Habitación:</span>
              <span class="rooms__li rooms__li--room">{{ room.roomName }}</span>
            </div>
            <div class="rooms__li">
              <span class="mobile">Sede:</span>
              <span class="rooms__li rooms__li--sede">{{ room.officeName }}</span>
            </div>
            <div class="rooms__li">
              <span class="mobile">Ciudad:</span>
              <span class="rooms__li rooms__li--city">{{ room.city.cityName }}</span>
            </div>
            <div class="rooms__comment">
              <span class="mobile">Comentario:</span>
              <div class="rooms__comment textarea" :id="`text-${room._id}`" contenteditable="true" v-html="room.comentMaintenence"></div>
            </div>
            <div class="rooms__li rooms__li--toggle">
              <span class="mobile">Habilitada:</span>
              <ToggleInput @isChecked="getModel($event, idx, 'state')" :checked="room.isEnable ? false : true" />
            </div>
            <div class="rooms__li rooms__li--toggle">
              <span class="mobile">Mantenimiento:</span>
              <ToggleInput @isChecked="getModel($event, idx, 'check')" :checked="room.statusMaintenence ? true : false" />
            </div>
            <div class="rooms__btn">
              <button class="rooms__saved" @click="sendData(room.id, room.officeName, room.city.cityName, room.roomName, idx)">
                <i class="fas fa-save"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="rooms.length < totalRooms">
        <button class="click-on-bottom" @click="showMore((cpage += 1))">Ver más</button>
      </div>
    </div>
    <div class="spinner" v-if="isLoading"></div>
  </div>
</template>

<script>
  import axios from "@/api/axios";
  import ToggleInput from "@/components/input/ToggleInput.vue";
  import filters from "@/views/main/reviewsAdmin/filtersMaintenance.vue";
  import swal from "@/js/swalAlerts";

  export default {
    data() {
      return {
        data: [],
        rooms: [],
        page: 1,
        cpage: 1,
        isLoading: false,
        totalRooms: null,
        statusFlag: false,
        isMantenience: null,
        head: ["Habitación", "Sede", "Ciudad", "Comentarios", "Habilitada", "Mantenimiento", ""],
      };
    },
    components: { ToggleInput, filters },
    methods: {
      getModel(model, id, x) {
        if (x === "state") {
          this.data[id][x] = !model;
        } else {
          this.data[id][x] = model;
        }
      },
      changevalue(id) {
        this.$refs.editref[id].classList.toggle("activeBtn");
      },
      sendData(id, sede, city, room, idx) {
        const textMaintenence = document.getElementById(`text-${id}`).innerHTML;
        const _data = new FormData();
        _data.append("id", id);
        _data.append("room", room);
        _data.append("sede", sede);
        _data.append("city", city);
        _data.append("comentMaintenence", textMaintenence);
        _data.append("statusMaintenence", this.data[idx].check);
        _data.append("isEnable", this.data[idx].state);
        swal
          .dataSendConfirm({
            message: "Cambiaras el estado de mantenimiento de la habitación",
            title: "",
          })
          .then(async (result) => {
            let res, msgRes;
            if (result.isConfirmed) {
              swal.dataSending();
              res = await axios.put(`/maintenence`, _data);
              if (res.data.success) {
                swal.dataSendSuccess({ message: res.data.success });
              } else {
                swal.dataSendError({ message: res.data.notSuccess, title: "" });
              }
            }
          });
      },
      async showMore(cpage) {
        this.isLoading = true;
        this.page = cpage;
        await this.getRooms(this.page);
        this.isLoading = false;
      },

      addClassEdit(id) {
        this.$refs.editref[id].classList.toggle("activeBtn");
      },
      addClassSend(id) {
        this.$refs.sendref[id].classList.toggle("activeBtn");
      },
      classHead(item) {
        switch (item) {
          case "":
            return "rooms__item--space";
          case "Comentarios":
            return "rooms__item--comment";
          default:
            return "";
        }
      },
      statusFlagChange() {
        this.statusFlag = !this.statusFlag;
      },
      getRequest: function(cpage) {
        let currentQuery = this.$route.fullPath.substring(1);
        if (currentQuery && currentQuery.includes("#")) {
          currentQuery = currentQuery.split("#")[0];
        }
        let _host = `/${currentQuery}`;
        return _host.includes("?") ? `${_host}&page=${cpage}` : `${_host}?page=${cpage}`;
      },
      async getRooms(cpage) {
        if (this.$route.name === "maintenence") {
          this.page = cpage;
          let _host = this.getRequest(this.page);
          const objRooms = await axios.get(_host);
          const a = objRooms.data.rooms.map((i) => ({ check: i.statusMaintenence, state: i.isEnable }));
          this.data.push(...a);
          this.rooms.push(...objRooms.data.rooms);
          this.totalRooms = objRooms.data.total;
        }
      },
    },
    computed: {
      async getfilterRooms() {
        if (this.$route.name === "maintenence") {
          this.cpage = 1;
          this.isLoading = true;
          let _host = this.getRequest(1);
          const objrooms = await axios.get(_host);
          this.data = objrooms.data.rooms.map((i) => ({ check: i.statusMaintenence, state: i.isEnable }));
          this.rooms = objrooms.data.rooms;
          this.totalRooms = objrooms.data.total;
          this.isLoading = false;
        }
      },
    },
    async beforeMount() {
      await this.getRooms(this.page);
    },
  };
</script>

<style lang="scss">
  .rooms {
    .activeBtn {
      display: none;
    }
    .toggle-switch {
      background: $japanese_laurel;
    }
    &__card {
      margin: 0 auto;
      margin-top: 8px;
      max-width: 1440px;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 0 2px 5px $alto;
      padding: 0;
      font-size: 14px;
    }

    &__head {
      display: none;
    }

    &__info {
      display: block;
      padding: $mpadding;
    }
    .mobile {
      font-weight: bold;
    }
    &__li {
      display: flex;
      margin: 1px;
      justify-content: space-between;
      &--room {
        color: $lipstick;
        font-weight: bold;
      }

      &--toggle {
        margin-bottom: 10px;
        align-items: center;
      }
    }
    &__btn {
      display: flex;
    }
    &__saved {
      margin: 0 auto;
      border: none;
      padding: 3px $mpadding/2;
      background-color: $lipstick;
      color: $white;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
      &:active {
        transform: scale(0.97);
      }
      &:hover {
        opacity: $opacity-button;
      }
    }
    .textarea {
      min-height: 52px;
      max-height: 52px;
      max-width: 241px;
      margin-bottom: 10px;
      overflow-y: auto;
      border: 1px solid $alto;
      border-radius: 5px;
      font-size: 13px;
      padding: 7px 5px;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
      }
      &:focus {
        outline: none;
      }
    }
  }
  @media (min-width: $tablet_width) {
    .rooms {
      .mobile {
        display: none;
      }
      &__card {
        font-size: 16px;
      }
      &__head {
        display: flex;
        margin: 0 auto;
        margin-top: 32px;
        margin-bottom: 10px;
        max-width: 1440px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0 2px 5px $alto;
        padding: $mpadding/2 $mpadding * 2;
        font-weight: bold;
      }
      &__item {
        width: 20%;
        text-align: center;
        &--space {
          width: 10%;
        }
        &--comment {
          width: 30%;
        }
      }
      &__info {
        display: flex;
        justify-content: space-around;
        padding: $mpadding/2 $mpadding * 2;
      }
      &__comment {
        width: 30%;
      }
      &__li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        margin: 0 auto;
        &--toggle {
          width: 20%;
          display: flex;
          margin: 0 auto;
        }
      }
      &__btn {
        display: inline;
        width: 10%;
      }
      &__saved {
        margin-left: 30px;
      }
      .textarea {
        min-height: 32px;
        max-height: 32px;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
</style>
